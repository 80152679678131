import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ContactFormWrap = styled.div`
  .form-group {
    &.has-error {
      margin-bottom: 40px;
    }
    label {
      position: absolute;
      left: 25px;
      top: 20px;
      background: #fff;
      padding: 0 5px;
      transition: ${themeGet('transition')};
    }
    input:focus ~ label,
    textarea:focus ~ label {
      top: -11px;
    }
  }
  .hidden {
    display: none;
  }
  .error {
    color: ${themeGet('colors.danger')};
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -26px;
  }
`;
