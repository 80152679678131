import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiChevronRight } from 'react-icons/fi';
import { Col, Row } from 'reactstrap';
import Button from '../../shared/button';
import { FormGroup, Input, Label, Textarea } from '../../shared/form';
import { ContactFormWrap } from './contact-form.stc';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const ContactForm = ({ formgroupCSS, textareaCSS, btnCSS }) => {
  const [isDisabled, setDisabled] = useState(undefined);
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = values => {
    fetch('/wiadomosc-wyslana', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...values })
    })
      .then(() => {
        if (typeof window !== `undefined`) {
          window.location.replace(`/wiadomosc-wyslana`);
        }
      })
      .catch(error => alert(error));

    setDisabled('disabled');
  };
  return (
    <ContactFormWrap>
      <form
        action="/wiadomosc-wyslana"
        name="contact"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
        subject="Wiadomość ze strony polskilogopeda.co.uk"
      >
        <input
          type="hidden"
          name="form-name"
          id="form-name"
          value="contact"
          aria-label="form name"
        />
        <p className="hidden">
          <label htmlFor="bot-field">
            Don't fill this out if you're human:{' '}
            <input name="bot-field" aria-label="bot field" />
          </label>
        </p>
        <Row>
          <Col lg={6}>
            <FormGroup
              {...formgroupCSS}
              className={`form-group ${errors.full_name && 'has-error'}`}
            >
              <Input
                type="text"
                name="full_name"
                id="full_name"
                ref={register({
                  required: 'podaj imię i nazwisko'
                })}
              />
              <Label htmlFor="full_name">Imię i nazwisko</Label>
              {errors.full_name && (
                <span className="error">{errors.full_name.message}</span>
              )}
            </FormGroup>
            <FormGroup
              {...formgroupCSS}
              className={`form-group ${errors.email && 'has-error'}`}
            >
              <Input
                type="email"
                name="email"
                id="email"
                ref={register({
                  required: 'E-mail jest wymagany',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'niepoprawny adres e-mail'
                  }
                })}
              />
              <Label htmlFor="email">Podaj swój e-mail</Label>
              {errors.email && (
                <span className="error">{errors.email.message}</span>
              )}
            </FormGroup>
            <FormGroup
              {...formgroupCSS}
              className={`form-group ${errors.subject && 'has-error'}`}
            >
              <Input
                type="text"
                name="subject"
                id="subject"
                ref={register({
                  required: 'Temat jest wymagany'
                })}
              />
              <Label htmlFor="subject">Temat</Label>
              {errors.subject && (
                <span className="error">{errors.subject.message}</span>
              )}
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup
              {...formgroupCSS}
              className={`form-group textarea ${errors.message && 'has-error'}`}
            >
              <Textarea
                {...textareaCSS}
                name="message"
                id="message"
                ref={register({
                  required: 'Wiadomość  wymagana',
                  minLength: { value: 10, message: 'Minimum 10 znaków' }
                })}
              ></Textarea>
              <Label htmlFor="message">Napisz wiadomość</Label>
              {errors.message && (
                <span className="error">{errors.message.message}</span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Button
              type="submit"
              {...btnCSS}
              icon={<FiChevronRight />}
              disabled={isDisabled}
            >
              Wyślij
            </Button>
          </Col>
        </Row>
      </form>
    </ContactFormWrap>
  );
};

ContactForm.defaultProps = {
  formgroupCSS: {
    mb: '30px'
  },
  textareaCSS: {
    height: ['120px', '150px', '200px', null, '240px']
  },
  btnCSS: {
    btnsize: 'fullwidth',
    varient: 'contained',
    shape: 'rounded',
    bg: '#f8777c',
    fontSize: '13px',
    letterSpacing: '1px',
    iconcss: {
      pl: '5px'
    }
  }
};

export default ContactForm;
